import React, { Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { XIcon } from "@heroicons/react/outline";

export default function Search({ usersAtCustomer, selectedUsers, setSelectedUsers, focusOnOpen }) {
    const [query, setQuery] = useState('')
    const [selectableUsers, setSelectableUsers] = useState(usersAtCustomer)
    const searchInputRef = useRef(null);
    const buttonRef = useRef();

    useEffect(() => {
        if (searchInputRef.current && focusOnOpen) {
            searchInputRef.current.focus();
        }
      }, []);

    useEffect(() => {
            const selectable = usersAtCustomer.filter(a => !selectedUsers.some(b => a.id === b.id));
            setSelectableUsers(selectable);
      }, [selectedUsers]);

    const filteredUsers =
        query === ''
            ? selectableUsers
            : selectableUsers.filter((user) =>
                user.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    function isSelected(value) {
        return selectedUsers.find((el) => el === value) ? true : false;
    }

    function handleSelect(value) {
        if (!isSelected(value)) {
            const selectedUsersUpdated = [
                ...selectedUsers,
                selectableUsers.find((el) => el === value)
            ];
            // Check if user allready belongs to group
            setSelectedUsers(selectedUsersUpdated);
        } else {
            //handleDeselect(value);
        }
        setQuery('');
    }

    function handleDeselect(value) {
        const selectedUsersUpdated = selectedUsers.filter((el) => el !== value);
        setSelectedUsers(selectedUsersUpdated);
        setQuery('');
    }

    const SelectedUser = ({ user }) => {
        return (
            <span
                className="flex flex-wrap pl-4 pr-2 py-2 m-1 justify-between items-center text-sm font-medium rounded-xl cursor-pointer border shadow-sm hover:shadow-md bg-white text-gray-500 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100">
                {user.name}
                <span
                    className="ml-2 rounded-full border border-transparent hover:border-gray-300 p-1"
                    onClick={() => handleDeselect(user)}>
                    <XIcon className="h-4 w-4 hover:text-red-500" />
                </span>
            </span>
        );
    }

    return (
        <>
            <Combobox onChange={(value) => handleSelect(value)}>
                <div className="relative">
                    <div className="relative w-full text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden">

                        <Combobox.Input
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            displayValue={(user) => user?.name}
                            placeholder="Search users"
                            ref={searchInputRef}
                            onClick={() => buttonRef.current?.click()}
                            onChange={(event) => setQuery(event.target.value)}>

                        </Combobox.Input>
                        <Combobox.Button 
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                            ref={buttonRef}
                        >
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Combobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredUsers.length === 0 && query !== '' ? (
                                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                                    No user found.
                                </div>
                            ) : (
                                filteredUsers.map((user) => (

                                    <Combobox.Option
                                        key={user.id}
                                        className={({ active }) =>
                                            `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-gray-400' : 'text-gray-900'
                                            }`
                                        }
                                        value={user}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span
                                                    className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                        }`}
                                                >
                                                    {user.name}
                                                </span>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
            <div className='px-2 pt-2 pb-2 mb-2 flex flex-wrap'>
                {
                    selectedUsers.map((u, i) => {
                        return <SelectedUser user={u} key={u.name} />
                    })
                }
            </div>
        </>
    );
}